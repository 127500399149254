<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>{{ pageTitle }}</h4>
      <div>
        <b-button
          v-if="componentMode === 'create'"
          type="submit"
          form="authorForm"
          class="mr-2"
          variant="outline-primary"
        >
          Создать
        </b-button>
        <b-button
          v-if="componentMode === 'edit'"
          type="submit"
          form="authorForm"
          class="mr-2"
          variant="outline-primary"
        >
          Сохранить
        </b-button>
        <b-button variant="outline-secondary" @click="cancel">Отмена</b-button>
      </div>
    </div>
    <b-row class="mt-2">
      <b-col cols="6">
        <form @submit.prevent="onSubmit" id="authorForm">
          <b-form-group label="Имя" label-class="pb-0">
            <b-form-input required v-model="model.first_name" autofocus />
          </b-form-group>
          <b-form-group label="Фамилия" label-class="pb-0">
            <b-form-input required v-model="model.last_name" />
          </b-form-group>
        </form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Place, Authors } from '@/request';

export default {
  name: 'Author',
  computed: {
    componentMode() {
      return this.$route?.meta?.mode || 'create';
    },
    pageTitle() {
      return this.componentMode === 'edit' ? 'Редактировать автора' : 'Создать автора';
    },
  },
  data() {
    return {
      model: {
        first_name: '',
        last_name: '',
      },
    };
  },
  beforeMount() {
    if (this.componentMode === 'edit') {
      this.getAuthor();
    }
  },
  methods: {
    async getAuthor() {
      try {
        Object.assign(this.model, await Authors.getAuthor(this.$route.params.id));
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async createAuthor() {
      try {
        await Authors.createAuthor(this.model);
        this.$bvToast.toast('Новый автор создан', { variant: 'success' });
        await this.$router.push({ name: 'Authors' });
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async updateAuthor() {
      try {
        await Authors.updateAuthor(this.$route.params.id, this.model);
        this.$bvToast.toast('Автор обновлен', { variant: 'success' });
        await this.$router.push({ name: 'Authors' });
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    onSubmit() {
      switch (this.componentMode) {
        case 'create':
          this.createAuthor();
          break;
        case 'edit': {
          this.updateAuthor();
          break;
        }
        default:
          return;
      }
    },
    cancel() {
      this.$router.push({ name: 'Authors' });
    },
  },
};
</script>

<style lang="scss" scoped></style>
